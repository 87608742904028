import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
} from "firebase/auth";

// Define the type for user data
interface UserData {
  email: string;
  password: string;
  displayName?: string;
}

// Define the return type of useAuthentication hook
interface UseAuthenticationReturn {
  auth: ReturnType<typeof getAuth>;
  createUser: (data: UserData) => void;
  error: string | null;
  loading: boolean | null;
  logout: () => JSX.Element | undefined;
  login: (data: { email: string; password: string }) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateDisplayName: (name: string) => void;
  changeEmail: (email: string, currentPassword: string) => Promise<void>;
  systemMessageReturn: string | null;
  systemMessageError: string | null;
  changePassword: (
    newPassword: string,
    currentPassword: string
  ) => Promise<void>;
  resendEmailVerification: () => void;
}

export const useAuthentication = (): UseAuthenticationReturn => {
  const [error, setError] = useState<string | null>(null);
  const [systemMessageReturn, setSystemMessageReturn] = useState<string | null>(
    null
  );
  const [systemMessageError, setSystemMessageError] = useState<string | null>(
    null
  );
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean | null>(null);

  const [cancelled, setCancelled] = useState<boolean>(false);

  const auth = getAuth();

  function checkIfIsCancelled() {
    if (cancelled) {
      return;
    }
  }

  const createUser = (data: UserData) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(({ user }) => {
        if (auth.currentUser) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              setSystemMessageReturn(
                `Um e-mail de verificação foi enviado para ${data.email}. Siga as instruções no e-mail para verificar sua conta.`
              );
            })
            .catch((err) => {
              setLoading(false);
              setSystemMessageError(err.message);
            });

          updateProfile(user, {
            displayName: data.displayName,
          })
            .then(() => {
              setLoading(false);
              return user;
            })
            .catch((error) => {
              console.log(error.message);

              let systemErrorMessage;

              if (error.message.includes("Password")) {
                systemErrorMessage =
                  "Sua senha deve conter pelo menos 6 caracteres.";
              } else if (error.message.includes("email-already")) {
                systemErrorMessage = "Este e-mial já está registrado.";
              } else {
                systemErrorMessage =
                  "Ocorreu um erro. Por favor, tente novamente mais tarde.";
              }
              setLoading(false);
              setSystemMessageError(systemErrorMessage);
            });
        }
      })
      .catch((error) => {
        console.log(error.message);

        let systemErrorMessage;

        if (error.message.includes("Password")) {
          systemErrorMessage =
            "Your password must contain at least 6 characters.";
        } else if (error.message.includes("email-already")) {
          systemErrorMessage =
            "This e-mail is already registered. Please enter another email address or go to the login page to retrieve your password.";
        } else {
          systemErrorMessage = "An error occurred. Please try again later.";
        }
        setLoading(false);
        setError(systemErrorMessage);
        setSystemMessageError(systemErrorMessage);
      });
  };

  const resendEmailVerification = () => {
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser)
        .then(() => {})
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setSystemMessageError("No current user to resend verification email.");
    }
  };

  const logout = () => {
    checkIfIsCancelled();
    signOut(auth);
    return <Navigate replace to="/login" />;
  };

  const login = async (data: { email: string; password: string }) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      if (auth.currentUser && !auth.currentUser.emailVerified) {
        setTimeout(() => {
          window.location.assign("/dashboard");
        }, 1000);
      }
      setLoading(false);
    } catch (error: any) {
      let systemErrorMessage;
      if (error.message.includes("user-not-found")) {
        systemErrorMessage = "Usuário não encontrado.";
      } else if (error.message.includes("wrong-password")) {
        systemErrorMessage = "Senha incorreta.";
      } else {
        systemErrorMessage =
          "Um erro ocorreu. Por favor, tente novamente mais tarde.";
      }
      setLoading(false);
      setError(systemErrorMessage);
    }
  };

  const resetPassword = async (email: string) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setLoading(false);
      setSuccess(true);
    } catch (error: any) {
      let systemErrorMessage;
      if (error.message.includes("user-not-found")) {
        systemErrorMessage = "Usuário não encontrado";
      } else {
        systemErrorMessage =
          "Um erro ocorreu. Por favor, tente novamente mais tarde.";
      }
      setLoading(false);
      setError(systemErrorMessage);
    }
  };

  const updateDisplayName = (name: string) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);

    if (auth.currentUser) {
      updateProfile(auth.currentUser, {
        displayName: name,
      })
        .then(() => {
          setLoading(false);
          setSystemMessageReturn("Your display name was updated.");
          setSystemMessageError(null);
        })
        .catch((error) => {
          let systemErrorMessage = "An error occurred. Please try again later.";

          setLoading(false);
          setSystemMessageError(systemErrorMessage);
          setSystemMessageReturn(null);
        });
      console.log(success);
      console.log(error);
    } else {
      setLoading(false);
      setSystemMessageError("No current user to update display name.");
    }
  };

  const changeEmail = async (email: string, currentPassword: string) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);

    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email!,
        currentPassword
      );

      reauthenticateWithCredential(user, credential)
        .then(async () => {
          try {
            await updateEmail(user, email);
            setLoading(false);
            setSystemMessageReturn("Your email was updated.");
            setSystemMessageError(null);
          } catch (error: any) {
            let systemErrorMessage =
              "An error occurred. Please try again later.";
            let userErrorMessage =
              error.code === "auth/wrong-password"
                ? "Incorrect current password. Please try again."
                : error.code === "auth/email-already-in-use"
                  ? "This email address is already in use. Please choose another."
                  : systemErrorMessage;

            setLoading(false);
            setSystemMessageError(userErrorMessage);
            setSystemMessageReturn(null);
          }
        })
        .catch((error: any) => {
          let systemErrorMessage = "An error occurred. Please try again later.";
          let userErrorMessage =
            "Incorrect current password. Please try again.";

          setLoading(false);
          setSystemMessageError(
            error.code === "auth/wrong-password"
              ? userErrorMessage
              : systemErrorMessage
          );
          setSystemMessageReturn(null);
        });
    } else {
      setLoading(false);
      setSystemMessageError("No current user to change email.");
    }
  };

  const changePassword = async (
    newPassword: string,
    currentPassword: string
  ) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(null);

    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email!,
        currentPassword
      );

      reauthenticateWithCredential(user, credential)
        .then(async () => {
          try {
            await updatePassword(user, newPassword);
            setLoading(false);
            setSystemMessageReturn("Your password was updated.");
            setSystemMessageError(null);
          } catch (error: any) {
            let systemErrorMessage =
              "An error occurred. Please try again later.";
            let userErrorMessage =
              error.code === "auth/wrong-password"
                ? "Incorrect current password. Please try again."
                : error.code === "auth/weak-password"
                  ? "Password should be at least 6 characters."
                  : systemErrorMessage;

            setLoading(false);
            setSystemMessageError(userErrorMessage);
            setSystemMessageReturn(null);
          }
        })
        .catch((error: any) => {
          let systemErrorMessage = "An error occurred. Please try again later.";
          let userErrorMessage =
            "Incorrect current password. Please try again.";

          setLoading(false);
          setSystemMessageError(
            error.code === "auth/wrong-password"
              ? userErrorMessage
              : systemErrorMessage
          );
          setSystemMessageReturn(null);
        });
    } else {
      setLoading(false);
      setSystemMessageError("No current user to change password.");
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return {
    auth,
    createUser,
    error,
    loading,
    logout,
    login,
    resetPassword,
    updateDisplayName,
    changeEmail,
    systemMessageReturn,
    systemMessageError,
    changePassword,
    resendEmailVerification,
  };
};
