import React, { useState } from "react";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import styles from "./TestimonialsPage.module.css";
import DOMPurify from "dompurify";
import ProfileHighlight from "../../components/ProfileHighlight/ProfileHighlight";

import Quotes from "../../img/quotes.svg";

interface Testimonial {
  id: string;
  testimonial: string;
  client: string;
}

const TestimonialsPage = () => {
  const {
    documents: testimonials,
    loading,
    error,
  } = useFetchDocuments<Testimonial>("testimonials");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isProfileHighlightLoaded, setIsProfileHighlightLoaded] =
    useState(false);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="bckImgTestimonials container-fluid py-lg-5 py-2 position-relative pb-lg-3 px-3">
        <div className="container-fluid bg-lg-white mb-4">
          <h1 className=" fw-bold py-4 text-white">Depoimentos</h1>
        </div>
        <div className="container">
          <div className="row">
            {testimonials &&
              testimonials.map((doc, index) => (
                <div key={doc.id} className="col-lg-4 col-md-6 mb-4">
                  <div className="p-4 bg-white h-100">
                    <img
                      src={Quotes}
                      alt="quotes"
                      className={`${styles.quotes} mt-4 mb-5`}
                    />
                    {doc?.testimonial && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(doc.testimonial || ""),
                        }}
                        className="text-start mb-5"
                      />
                    )}
                    <p className="text-red fw-bold mt-5">{doc.client}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {testimonials && (
          <div className=" container-fluid py-lg-5 py-2 position-relative px-lg-0 px-3">
            <ProfileHighlight
              onLoad={() => setIsProfileHighlightLoaded(true)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TestimonialsPage;
