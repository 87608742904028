import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const storage = getStorage();

// Create the file metadata
const metadata = {
  contentType: "image/jpeg",
};

export const uploadImageToStorage = async (
  file: File
): Promise<string | null> => {
  const storageRef = ref(storage, "images/" + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  return new Promise((resolve, reject) => {
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        switch (snapshot.state) {
          case "paused":
            // console.log("Upload is paused");
            break;
          case "running":
            // console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            reject("Unauthorized access");
            break;
          case "storage/canceled":
            // User canceled the upload
            reject("Upload canceled");
            break;
          // Handle other error cases as needed
          default:
            reject("Unknown error occurred");
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export const deleteImageFromStorage = async (
  image: string
): Promise<string | null> => {
  try {
    const storage = getStorage();
    const desertRef = ref(storage, image);

    await deleteObject(desertRef);
    return "Image deleted successfully";
  } catch (error) {
    console.error("Failed to delete image:", error);
    return null;
  }
};
