import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import styles from "./CardGrid.module.css";

type DocumentType = {
  id: string;
  title: string;
  highlight: string;
  full_text: string;
  image: string;
  slug: string;
  intro: string;
};

interface CardGridProps {
  onLoad: () => void;
}

const CardGrid: React.FC<CardGridProps> = ({ onLoad }) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  const { documents, loading, error } =
    useFetchDocuments<DocumentType>("services");

  const bckColors: string[] = ["#EEC1A0", "#E8B38B", "#EAC996", "#E0C9B7"];
  const numbersToCheck: number[] = [2, 3, 6, 7, 10, 11, 14, 15];

  const isMobileDevice = () => {
    return (
      typeof window !== "undefined" &&
      (window.navigator.userAgent.includes("Mobi") || window.innerWidth <= 980)
    );
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());

    const handleResize = () => setIsMobile(isMobileDevice());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      onLoad();
    }
  }, [documents, onLoad]);

  const handleCardClick = (slug: string, identifier: string) => {
    navigate(`/psicologia/${slug}`, { state: { id: identifier } });
    window.location.reload();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mt-lg-5 mt-2 mb-5" id="como_ajudar">
      <h1 className="text-center fs-2">Como posso te ajudar:</h1>
      <hr className={styles.hrCustom} />
      <div className="row g-0 mt-4">
        {documents &&
          documents.length > 0 &&
          documents.map((doc, index) => (
            <React.Fragment key={doc.id}>
              {numbersToCheck.includes(index) ? (
                <>
                  <div className="col-6 col-lg-3 d-flex">
                    <div className="card h-100 w-100 d-flex flex-column border-0">
                      <img
                        src={doc.image}
                        className={`${styles.cardServices} rounded-0 flex-grow-1`}
                        alt={doc.title}
                      />
                    </div>
                  </div>
                  <div
                    className="col-6 col-lg-3 d-flex"
                    onClick={() => handleCardClick(doc.slug, doc.id)}
                  >
                    <Link
                      to={`/psicologia/${doc.slug}`}
                      state={{ id: doc.id }}
                      className={`${styles.cardService} h-100 w-100 d-flex flex-column`}
                    >
                      <div
                        className={`${styles.cardBody} card-body border-0 rounded-0 d-flex flex-column align-items-center justify-content-between p-4`}
                        style={{
                          backgroundColor: bckColors[index % bckColors.length],
                        }}
                      >
                        <h4 className={`${styles.cardTitle}`}>{doc.intro}</h4>
                        <h3 className="card-text text-center">
                          {doc.highlight}
                        </h3>
                        <button className="custom-outline-btn align-self-center">
                          Entenda mais
                        </button>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="col-6 col-lg-3 d-flex"
                    onClick={() => handleCardClick(doc.slug, doc.id)}
                  >
                    <Link
                      to={`/psicologia/${doc.slug}`}
                      state={{ id: doc.id }}
                      className={`${styles.cardService} h-100 w-100 d-flex flex-column`}
                    >
                      <div
                        className={`${styles.cardBody} card-body border-0 rounded-0 d-flex flex-column align-items-center justify-content-between p-4`}
                        style={{
                          backgroundColor: bckColors[index % bckColors.length],
                        }}
                      >
                        <h4 className={`${styles.cardTitle}`}>{doc.intro}</h4>
                        <h3 className="card-text text-center">
                          {doc.highlight}
                        </h3>
                        <button className="custom-outline-btn align-self-center">
                          Entenda mais
                        </button>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 col-lg-3  d-flex">
                    <div className="card h-100 w-100 d-flex flex-column border-0">
                      <img
                        src={doc.image}
                        className={`${styles.cardServices} rounded-0 flex-grow-1`}
                        alt={doc.title}
                      />
                    </div>
                  </div>
                </>
              )}
              {index === documents.length - 1 &&
                index % 2 === 0 &&
                !isMobile && (
                  <>
                    <div className="col-6 col-lg-3  d-flex">
                      <div className="card h-100 w-100 d-flex flex-column border-0">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/illustration_free.png?alt=media&token=ce844947-daee-4110-9122-37ccfe9e52bc"
                          className="card-img-top rounded-0 flex-grow-1"
                          alt="Saúde mental"
                        />
                      </div>
                    </div>

                    <div className="col-6 col-lg-3  d-flex">
                      <div className="card h-100 w-100 d-flex flex-column border-0">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/saude_mental.jpg?alt=media&token=ac65566e-a65f-45e0-a6ba-619d9a64de95"
                          className="card-img-top rounded-0 flex-grow-1"
                          alt="Saúde mental"
                        />
                      </div>
                    </div>
                  </>
                )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default CardGrid;
