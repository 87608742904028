import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import { doc, deleteDoc } from "firebase/firestore";
import { deleteImageFromStorage } from "../hooks/uploadImageToStorage";

// Interface para definir a estrutura do documento

// Definindo o estado inicial
interface State {
  loading: boolean | null;
  error: string | null;
}

const initialState: State = {
  loading: null,
  error: null,
};

// Definindo tipos de ações
type Action =
  | { type: "LOADING" }
  | { type: "DELETED_DOC" }
  | { type: "ERROR"; payload: string };

const deleteReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null };
    case "DELETED_DOC":
      return { loading: false, error: null };
    case "ERROR":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Definindo o hook useDeleteDocument
export const useDeleteDocument = (docCollection: string) => {
  const [response, dispatch] = useReducer(deleteReducer, initialState);

  // Lidando com vazamento de memória
  const [cancelled, setCancelled] = useState(false);

  const checkCancelBeforeDispatch = (action: Action) => {
    if (!cancelled) {
      dispatch(action);
    }
  };

  const deleteDocument = async (id: string, image?: string) => {
    checkCancelBeforeDispatch({ type: "LOADING" });

    try {
      await deleteDoc(doc(db, docCollection, id));

      // Verifica se a propriedade 'image' está presente no documento antes de tentar deletá-la
      if (image) {
        await deleteImageFromStorage(image);
      }

      checkCancelBeforeDispatch({
        type: "DELETED_DOC",
      });
    } catch (error) {
      if (error instanceof Error) {
        checkCancelBeforeDispatch({ type: "ERROR", payload: error.message });
      } else {
        checkCancelBeforeDispatch({
          type: "ERROR",
          payload: "An unknown error occurred",
        });
      }
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { deleteDocument, response };
};
