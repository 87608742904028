import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import { updateDoc, doc, DocumentReference } from "firebase/firestore";

interface UpdateState {
  loading: boolean | null;
  error: string | null;
  success: boolean; // Adicionando a propriedade de sucesso
}

type UpdateAction =
  | { type: "LOADING" }
  | { type: "UPDATED_DOC"; payload: any }
  | { type: "ERROR"; payload: string }
  | { type: "SUCCESS" }; // Adicionando ação para indicar sucesso

const initialState: UpdateState = {
  loading: null,
  error: null,
  success: false, // Inicialmente definido como falso
};

const updateReducer = (
  state: UpdateState,
  action: UpdateAction
): UpdateState => {
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null, success: false }; // Reinicie o estado de sucesso ao carregar
    case "UPDATED_DOC":
      return { loading: false, error: null, success: true }; // Defina o sucesso como verdadeiro ao atualizar com sucesso
    case "ERROR":
      return { loading: false, error: action.payload, success: false }; // Reinicie o estado de sucesso em caso de erro
    case "SUCCESS":
      return { ...state, success: true }; // Defina o sucesso como verdadeiro diretamente
    default:
      return state;
  }
};

export const useUpdateDocument = (docCollection: string) => {
  const [response, dispatch] = useReducer(updateReducer, initialState);

  // deal with memory leak
  const [cancelled, setCancelled] = useState<boolean>(false);

  const checkCancelBeforeDispatch = (action: UpdateAction) => {
    if (!cancelled) {
      dispatch(action);
    }
  };

  const updateDocument = async (uid: string, data: any) => {
    checkCancelBeforeDispatch({ type: "LOADING" });

    try {
      const docRef: DocumentReference = doc(db, docCollection, uid);

      const updatedDocument = await updateDoc(docRef, data);

      checkCancelBeforeDispatch({
        type: "UPDATED_DOC",
        payload: updatedDocument,
      });

      // console.log("Document updated:", response);
    } catch (error: any) {
      console.log(error);
      checkCancelBeforeDispatch({ type: "ERROR", payload: error.message });
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { updateDocument, response };
};
