// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAN0LVoLi2lzsWomHoznfoUtEc3rck-JNM",
  authDomain: "jocemara-mognon-website.firebaseapp.com",
  projectId: "jocemara-mognon-website",
  storageBucket: "jocemara-mognon-website.appspot.com",
  messagingSenderId: "548940000753",
  appId: "1:548940000753:web:31115b71c9acede9cee850",
  measurementId: "G-DT0BTBRD30",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };
