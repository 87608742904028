import React, { useContext, createContext, ReactNode } from "react";

interface UserType {
  // Defina as propriedades necessárias para representar o usuário
  uid: string;
  displayName: string | null;
  email: string | null;
  // ...
}

interface AuthContextType {
  user: UserType | null; // Defina user como UserType ou null inicialmente
}

const defaultAuthContextValue: AuthContextType = {
  user: null, // Inicializa user como null ou com um valor padrão
};

const AuthContext = createContext<AuthContextType>(defaultAuthContextValue);

interface AuthProviderProps {
  children: ReactNode;
  value: AuthContextType;
}

export function AuthProvider({ children, value }: AuthProviderProps) {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthValue(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthValue must be used within an AuthProvider");
  }
  return context;
}
