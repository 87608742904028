import React, { useEffect } from "react";
import styles from "./TopHighlight.module.css";
import { useFetchSingleFile } from "../../hooks/useFetchSingleFile";
import HighlightLastWords from "../../components/HighLightLastwords/HighLightLastWords";
import fotoJoce from "./img/joce_highlight.jpg";
import WhatsAppLink from "../WhatssAppLink/WhatsAppLink";

type DestaqueDocument = {
  id: string;
  lead: string;
  descricao: string;
  texto_botao: string;
  ordination: number;
};

interface TopHighlightProps {
  onLoad: () => void;
}

const TopHighlight: React.FC<TopHighlightProps> = ({ onLoad }) => {
  const {
    document: destaque,
    loading,
    error,
  } = useFetchSingleFile<DestaqueDocument>("home_destaque");

  useEffect(() => {
    if (destaque) {
      onLoad();
    }
  }, [destaque, onLoad]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={`${styles.homeContainer} container-fluid d-flex px-0`}>
      <div className={`${styles.wrappedContainer} container-fluid`}>
        <div className="container">
          {destaque && (
            <div
              className="row d-flex justify-content-center py-lg-5 py-0"
              key={destaque.id}
            >
              <div className="col-lg-6 py-3 px-3 py-lg-0 px-lg-0">
                <h1 className={`${styles.homeH1} mb-4`}>
                  <HighlightLastWords description={destaque.lead} />
                </h1>
                <p className={`${styles.customLead} mb-5`}>
                  {destaque.descricao}
                </p>
                <WhatsAppLink className="custom-primary-btn" />
              </div>
              <div className="col-lg-5 d-flex justify-content-end">
                <img
                  src={fotoJoce}
                  className={`${styles.highlightImage} d-none d-lg-block`}
                  alt="Jocemara Mognon"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopHighlight;
