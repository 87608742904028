import React, { useState } from "react";
import DOMPurify from "dompurify";
import styles from "./Service.module.css";

//components
import WhatsAppLink from "../../components/WhatssAppLink/WhatsAppLink";
import CardGrid from "../../components/CardGrid/CardGrid";
import ProfileHighlight from "../../components/ProfileHighlight/ProfileHighlight";

//hooks
import { useParams } from "react-router-dom";
import { useFetchService } from "../../hooks/useFetchService";

type ServiceDocumentType = {
  id: string;
  title: string;
  intro: string;
  image: string;
  full_text: string;
};

const Service = () => {
  const { slug } = useParams<{ slug: string }>();
  const id = null;

  const [isCardGridLoaded, setIsCardGridLoaded] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isProfileHighlightLoaded, setIsProfileHighlightLoaded] =
    useState(false);

  const {
    document: service,
    loading,
    error,
  } = useFetchService<ServiceDocumentType>("services", slug || "", id || "");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="bckImg container-full">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9 col-11 bg-white my-lg-5 py-lg-5 my-3 py-3">
              <div className="row">
                <div className="col-lg-2 col-12">
                  {" "}
                  <img
                    src={service?.image}
                    alt={service?.title}
                    className={`${styles.serviceImg}`}
                  />
                </div>
                <div className="col-lg-9 col-12 ps-lg-5 ps-3 pt-lg-0 pt-3">
                  <h1 className="fs-3 text-start">{service?.title}</h1>
                </div>
              </div>
              <div className="row d-flex justify-content-start mt-lg-5 mt-2">
                <div className="col-lg-2 col-12"></div>
                <div className="col-lg-9 col-12">
                  {/* <p className="text-start mb-5">{service?.full_text}</p> */}
                  {service?.full_text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(service.full_text || ""),
                      }}
                      className="text-start mb-5"
                    />
                  )}
                  <WhatsAppLink className="custom-primary-btn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {service && <CardGrid onLoad={() => setIsCardGridLoaded(true)} />}
      {isCardGridLoaded && (
        <div className="bckImg container-fluid py-lg-5 py-2 position-relative px-lg-0 px-3">
          <ProfileHighlight onLoad={() => setIsProfileHighlightLoaded(true)} />
        </div>
      )}
    </>
  );
};

export default Service;
