import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import {
  collection,
  query,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
  where,
} from "firebase/firestore";

export type FetchProps = string;

export const useFetchService = <T extends { id: string }>(
  docCollection: FetchProps,
  slug: string,
  id?: string
) => {
  // console.log("Fetching document:", docCollection, slug, id);
  const [document, setDocument] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // deal with memory leak
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    async function loadData() {
      if (cancelled) {
        return;
      }

      setLoading(true);

      const collectionRef = collection(db, docCollection);

      try {
        let q;

        if (id) {
          q = query(
            collectionRef,
            where("slug", "==", slug),
            where("id", "==", id)
          );
        } else {
          q = query(collectionRef, where("slug", "==", slug));
        }

        const unsubscribe = onSnapshot(
          q,
          (querySnapshot: QuerySnapshot<DocumentData>) => {
            const doc = querySnapshot.docs[0];

            if (doc) {
              setDocument({
                id: doc.id,
                ...doc.data(),
              } as T);
            } else {
              setDocument(null);
              console.log("No document found in the collection.");
            }
          },
          (error) => {
            console.error("Error fetching document:", error);
            setError(error.message);
          }
        );

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [docCollection, slug, id, cancelled]);

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { document, loading, error };
};
