import React from "react";
import WhatsIconImg from "../../img/whats_icon.svg";

type WhatsProps = {
  className: string;
};

const WhatsAppLink: React.FC<WhatsProps> = ({ className }) => {
  const phoneNumber = "+554198782854";
  const message =
    "Olá, estava navegando no site da Jocemara Mognon, gostaria de mais informações.";
  const formattedNumber = phoneNumber.replace(/[^0-9]/g, "");
  const encodedMessage = encodeURIComponent(message);

  const whatsappURL = `https://wa.me/${formattedNumber}?text=${encodedMessage}`;

  if (className === "icon_whatsapp") {
    return (
      <a
        className="whatsIcon"
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WhatsIconImg} alt="Entre em contato pelo Whatsapp" />
      </a>
    );
  } else if (className === "nav-link") {
    return (
      <a className="nav-link" href={whatsappURL}>
        Contato
      </a>
    );
  } else {
    return (
      <a
        className={className}
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <u>Agende uma consulta</u>
      </a>
    );
  }
};

export default WhatsAppLink;
