import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadImageToStorage } from "../../hooks/uploadImageToStorage";
import { useInsertDocument } from "../../hooks/useInsertDocument";

const AddNewContent: React.FC = () => {
  const { insertDocument } = useInsertDocument("services");
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  const titleRef = useRef<HTMLInputElement>(null); // Ref for title input

  // Estados individuais para cada campo do formulário
  const [title, setTitle] = useState("");
  const [highlight, setHighlight] = useState("");
  const [fullText, setFullText] = useState("");
  const [image, setImage] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [intro, setIntro] = useState("");
  const [sequence, setSequence] = useState<number | undefined>(undefined);

  function normalizeTitle(title: string) {
    const normalizedTitle = title
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();
    return normalizedTitle;
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = await uploadImageToStorage(file);
      if (imageUrl) {
        setImage(imageUrl);
        setCardImage(imageUrl); // Assuming cardImage should also be set to imageUrl
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "highlight":
        setHighlight(value);
        break;
      case "intro":
        setIntro(value);
        break;
      case "sequence":
        setSequence(Number(value));
        break;
      default:
        break;
    }
  };

  const handleEditorChange = (value: string) => {
    setFullText(value);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!title) newErrors.title = "Título é obrigatório";
    if (!highlight) newErrors.highlight = "Texto de destaque é obrigatório";
    if (!intro) newErrors.intro = "Texto de introdução é obrigatório";
    if (!fullText) newErrors.fullText = "Texto completo é obrigatório";
    if (!image) newErrors.image = "Imagem é obrigatória";
    if (sequence === undefined)
      newErrors.sequence = "Ordem de exibição é obrigatória";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    const normalizedSlug = normalizeTitle(title);

    const document = {
      title,
      highlight,
      full_text: fullText,
      image,
      card_image: cardImage,
      intro,
      sequence,
      slug: normalizedSlug,
    };

    insertDocument(document);
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        navigate("/admin/edit/como-posso-te-ajudar/");
      }, 2000);
    }
  }, [submitted, navigate]);

  useEffect(() => {
    // After submission, focus on the title input
    if (submitted && titleRef.current) {
      titleRef.current.focus();
      titleRef.current.select(); // Selecting text in the input
    }
  }, [submitted]);

  return (
    <div className="container-fluid pb-4 pt-5 mx-0 bckImg">
      <div className="container p-lg-4 bg-white">
        <h2 className="text-start pb-lg-4">
          Adicionando novo item a seção: Como posso te ajudar
        </h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {submitted ? (
              <div style={{ height: "50vh" }}>
                <div className="alert alert-success" role="alert">
                  Conteúdo adicionado com sucesso. Redirecionando para
                  Dashboard...
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-100 text-start">
                <div className="form-group mb-4">
                  <label htmlFor="title" className="fw-bold">
                    Título *
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleInputChange}
                    className={`form-control ${errors.title ? "is-invalid" : ""}`}
                    ref={titleRef} // Assigning the ref to the input
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <div>
                    <p className="fw-bold">Imagem:{"  "}</p>
                    <small>
                      *Altera tanto no card quanto na página do conteúdo. Insira
                      imagens (jpg x png) com a mesma altura e largura, para que
                      as imagens não fiquem distorcidas.
                    </small>
                  </div>
                  <div>
                    <img
                      src={image}
                      alt=""
                      width="150px"
                      className="img-fluid pb-3"
                    />
                    <input
                      type="file"
                      className={`form-control ${errors.image ? "is-invalid" : ""}`}
                      id="image"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    {errors.image && (
                      <div className="invalid-feedback">{errors.image}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="intro" className="fw-bold">
                    Texto de introdução do card *
                  </label>
                  <input
                    type="text"
                    name="intro"
                    value={intro}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                  {errors.intro && (
                    <div className="invalid-feedback">{errors.intro}</div>
                  )}
                </div>
                <div className="row pb-lg-4">
                  <div className="form-group col-lg-4">
                    <label htmlFor="highlight" className="fw-bold">
                      Texto de destaque do card *
                    </label>
                    <input
                      type="text"
                      name="highlight"
                      value={highlight}
                      onChange={handleInputChange}
                      className={`form-control ${errors.highlight ? "is-invalid" : ""}`}
                    />
                    {errors.highlight && (
                      <div className="invalid-feedback">{errors.highlight}</div>
                    )}
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="sequence" className="fw-bold">
                      Ordem de exibição *{" "}
                      <small>
                        (ex: 1, 2, 3, 4...) insira um número inteiro
                      </small>
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      value={sequence !== undefined ? sequence : ""}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                      className={`form-control ${errors.sequence ? "is-invalid" : ""}`}
                    />
                    {errors.sequence && (
                      <div className="invalid-feedback">{errors.sequence}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="full_text" className="fw-bold">
                    Texto completo: *
                  </label>
                  <ReactQuill
                    value={fullText}
                    onChange={handleEditorChange}
                    className={`form-control ${errors.fullText ? "is-invalid" : ""}`}
                    theme="snow"
                  />
                  {errors.fullText && (
                    <div className="invalid-feedback">{errors.fullText}</div>
                  )}
                </div>

                <div className="row text-center mt-3">
                  <div className="form-group mb-4 align-items-end d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn rounded-pill bg-black text-white"
                      style={{ width: "200px" }}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewContent;
