import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/config";
import {
  collection,
  addDoc,
  Timestamp,
  DocumentReference,
} from "firebase/firestore";

// Definindo o estado inicial
interface State {
  loading: boolean | null;
  error: string | null;
}

const initialState: State = {
  loading: null,
  error: null,
};

// Definindo tipos de ações
type Action =
  | { type: "LOADING" }
  | { type: "INSERTED_DOC"; payload: DocumentReference }
  | { type: "ERROR"; payload: string };

const insertReducer = (state: State, action: Action): State => {
  // console.log(state, action);
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null };
    case "INSERTED_DOC":
      return { loading: false, error: null };
    case "ERROR":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

interface UseInsertDocumentResponse {
  insertDocument: (document: any) => Promise<void>;
  response: State;
}

export const useInsertDocument = (
  docCollection: string
): UseInsertDocumentResponse => {
  const [response, dispatch] = useReducer(insertReducer, initialState);

  // Lidando com vazamento de memória
  const [cancelled, setCancelled] = useState(false);

  const checkCancelBeforeDispatch = (action: Action) => {
    if (!cancelled) {
      dispatch(action);
    }
  };

  const insertDocument = async (document: any) => {
    checkCancelBeforeDispatch({ type: "LOADING" });

    try {
      const newDocument = { ...document, createdAt: Timestamp.now() };

      const insertedDocument = await addDoc(
        collection(db, docCollection),
        newDocument
      );

      checkCancelBeforeDispatch({
        type: "INSERTED_DOC",
        payload: insertedDocument,
      });
    } catch (error) {
      if (error instanceof Error) {
        checkCancelBeforeDispatch({ type: "ERROR", payload: error.message });
      } else {
        checkCancelBeforeDispatch({
          type: "ERROR",
          payload: "An unknown error occurred",
        });
      }
    }
    // console.log(response);
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { insertDocument, response };
};
