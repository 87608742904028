import styles from "./Footer.module.css";
import logoJocemara from "../../img/logo_jocemara.svg";
import DOMPurify from "dompurify";
//hooks
import { useFetchSingleFile } from "../../hooks/useFetchSingleFile";
interface FooterProps {
  id: string;
  crp: string;
  address: string;
}

const Footer: React.FC = () => {
  const {
    document: address,
    loading,
    error,
  } = useFetchSingleFile<FooterProps>("address");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <footer className="container-fluid">
      <div className="row p-lg-4 pt-3 bckBeige">
        <div className="col-12 col-lg-6 d-lg-flex d-none justifify-content-start ">
          <img
            src={logoJocemara}
            alt="Logo Jocemara Mognon"
            className={styles.footerLogo}
          />
        </div>
        <div className="col-12 col-lg-6 text-lg-end">
          <address className={styles.footerAddress}>
            {address?.crp}
            {address?.address && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(address.address || ""),
                }}
                className={`${styles.noPadding} text-right mb-3 mt-3`}
              />
            )}
          </address>
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-0">
          <div className={`${styles.footerCopyright} bckBrown py-3`}>
            &copy; {address?.crp} | Todos os direitos reservados.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
