import React, { useEffect } from "react";
import fotoProfile from "./img/profile_jocemara_tiny.png";
import styles from "./ProfileHighlight.module.css";
import { Link } from "react-router-dom";

//components
import WhatsAppLink from "../../components/WhatssAppLink/WhatsAppLink";

//hooks
import { useFetchSingleFile } from "../../hooks/useFetchSingleFile";

interface ProfileHighlightProps {
  id: string;
  title: string;
  lead: string;
  mission: string;
}

interface Props {
  onLoad: () => void;
}

const ProfileHighlight: React.FC<Props> = ({ onLoad }) => {
  const {
    document: profile,
    loading,
    error,
  } = useFetchSingleFile<ProfileHighlightProps>("profile_highlight");

  useEffect(() => {
    if (profile) {
      onLoad();
    }
  }, [profile, onLoad]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container-fluid position-relative bckBeige my-5">
      <img
        src={fotoProfile}
        alt="Jocemara Mognon"
        className={`${styles.profileImg}`}
      />
      <div className="container pt-lg-5 pt-2 pb-4">
        <div className="row d-flex mt-3 justify-content-center">
          <div className="col-lg-12 col-12">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 col-12"></div>
              <div className="col-lg-6 col-12">
                {profile && (
                  <>
                    <h1 className="text-lg-start text-center fs-2 fw-bold">
                      {profile.title}
                    </h1>
                    <p className="text-start">{profile.lead}</p>
                    <h3 className="text-start mt-lg-5 mt-2">Minha missão</h3>
                    <p className="text-start">{profile.mission}</p>
                    <div className=" mt-lg-5 mt-2 d-flex flex-column flex-lg-row justify-content-lg-start justify-content-center">
                      <Link
                        to="/jocemara-mognon"
                        className="custom-profile-btn me-lg-3 me-0 mb-3 mb-lg-0"
                      >
                        Conheça mais sobre mim{" "}
                      </Link>
                      <WhatsAppLink className="custom-primary-btn" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <p className="text-center"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHighlight;
