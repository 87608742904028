import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/logo_jocemara.svg";
import styles from "./NavBar.module.css";
import WhatsAppLink from "../WhatssAppLink/WhatsAppLink";

const NavBar: React.FC = () => {
  return (
    <nav
      className={`${styles.customNavbar} navbar navbar-expand-lg navbar-light bg-white pe-2 pe-lg-3 py-lg-4`}
    >
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img
            src={logo}
            alt="Logo Jocemara Mognon"
            className={`${styles.logoJocemara} d-inline-block align-top ps-0 ps-lg-4`}
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav my-0">
            <li className="nav-item mx-3">
              <NavLink
                to="/jocemara-mognon"
                className={({ isActive }) =>
                  isActive ? `${styles.active} nav-link` : "nav-link"
                }
                aria-current="page"
              >
                <span>Sobre mim</span>
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink
                to="/como-posso-te-ajudar"
                className={({ isActive }) =>
                  isActive ? `${styles.active} nav-link` : "nav-link"
                }
              >
                <span>Como posso ajudar</span>
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink
                to="/depoimentos"
                className={({ isActive }) =>
                  isActive ? `${styles.active} nav-link` : "nav-link"
                }
              >
                <span>Depoimentos</span>
              </NavLink>
            </li>

            <li className="nav-item mx-3">
              <WhatsAppLink className="nav-link" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
