import React, { useState, useEffect, FormEvent } from "react";
import styles from "./Login.module.css";
import psi from "./img/head_psi.png";

import { useAuthentication } from "../../hooks/useAuthentication";

import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [eye, setEye] = useState(false);

  const Eye = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setEye(true);
    } else {
      setPasswordType("password");
      setEye(false);
    }
  };

  const { login, error: authError, loading } = useAuthentication();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    const user = {
      email,
      password,
    };

    await login(user);
  };

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  return (
    <div className="bckImg container-fluid mt-5 py-4">
      <div className="row d-flex justify-content-center">
        <div className="col-5 d-flex justify-content-center">
          <img src={psi} alt="PSI" />
        </div>
        <div className="col-4 bg-white p-5">
          <h4 className="mb-3 text-start">Área administrativa - Login</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start">
              <label
                htmlFor="inputEmail"
                className={`${styles.loginLabel} form-label`}
              >
                Email
              </label>
              <input
                type="email"
                className={`${styles.loginInput} form-control`}
                id="inputEmail"
                name="email"
                required
                placeholder="Seu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3 text-start position-relative">
              <label
                htmlFor="inputPassword"
                className={`${styles.loginLabel} form-label`}
              >
                Password
              </label>
              <input
                type={passwordType}
                className={`${styles.loginInput} form-control rounded-pill mb-4`}
                name="password"
                required
                placeholder="Insert password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                onClick={Eye}
                className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                style={{ top: 41, right: "10px" }}
              ></i>
            </div>
            <div className="row g-0 ">
              <div className="col-12 text-start mb-3 d-flex justify-content-center">
                {!loading && (
                  <button className="btn w-100 rounded-pill bg-black text-white">
                    Login
                  </button>
                )}
                {loading && (
                  <button className="btn" disabled>
                    Wait...
                  </button>
                )}
                {error && (
                  <small className="text-danger d-block pt-2">{error}</small>
                )}
              </div>

              <Link to={`/reset`} className="col-6 text-center pt-2">
                <small>Esqueci minha senha</small>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
