import React from "react";
import { Link } from "react-router-dom";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import styles from "./Testimonials.module.css";
import Quotes from "../../img/quotes.svg";
import DOMPurify from "dompurify";

//components
import WhatsAppLink from "../../components/WhatssAppLink/WhatsAppLink";

interface Testimonial {
  id: string;
  testimonial: string;
  client: string;
}

const Testimonials = () => {
  const {
    documents: testimonials,
    loading,
    error,
  } = useFetchDocuments<Testimonial>("testimonials");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={`${styles.customContainer} container`} id="depoimentos">
      <div className="row d-flex mt-3 justify-content-center align-items-center flex-column">
        <small className="text-white d-block d-lg-none">
          Deslize para ver mais depoimentos:{" "}
        </small>
        <div
          className={`${styles.testimonialContainer} col-lg-10 col-12 p-lg-5 p-4 pt-lg-4 mb-3`}
        >
          {testimonials && testimonials.length > 0 && (
            <>
              <h1>Depoimentos</h1>
              <img
                src={Quotes}
                alt="Quotes"
                className={`${styles.customQuotes} my-3`}
              />
              <div
                id="carouselTestimonials"
                className="carousel slide "
                data-bs-interval="3000"
                data-bs-touch="true"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {testimonials.map((item, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.testimonial || ""),
                        }}
                        className={`${styles.noPadding} text-black mt-4`}
                      />

                      <p className={`${styles.clientName} mt-5`}>
                        {item.client}
                      </p>
                    </div>
                  ))}
                </div>
                <button
                  className={`${styles.customButtonLeft}  d-lg-flex d-none`}
                  type="button"
                  data-bs-target="#carouselTestimonials"
                  data-bs-slide="prev"
                >
                  <span
                    className={`${styles.customIconLeft} carousel-control-prev-icon`}
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className={`${styles.customButtonRight} d-lg-flex d-none`}
                  type="button"
                  data-bs-target="#carouselTestimonials"
                  data-bs-slide="next"
                >
                  <span
                    className={`${styles.customIconRight} carousel-control-next-icon`}
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-lg-3 mt-lg-3 mb-3">
          <WhatsAppLink className="custom-primary-outline-btn" />
        </div>
        <div className="col-lg-3 mt-lg-3 mb-3">
          <Link to="/depoimentos" className="text-white fw-bold">
            <u>Ver todos os depoimentos</u>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
