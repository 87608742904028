import React from "react";

const HighlightLastWords = ({ description }: { description: string }) => {
  const words = description.split(" ");

  if (words.length < 2) {
    return <>{description}</>;
  }

  const firstPart = words.slice(0, -2).join(" ");
  const lastWords = words.slice(-2).join(" ");

  return (
    <>
      {firstPart}{" "}
      <span style={{ color: "#ED8547", fontWeight: "bold" }}>{lastWords}</span>
    </>
  );
};

export default HighlightLastWords;
