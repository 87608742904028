import React, { useState } from "react";

//hooks
import CardGrid from "../../components/CardGrid/CardGrid";
import ProfileHighlight from "../../components/ProfileHighlight/ProfileHighlight";

const HowCanIHelp = () => {
  const [isCardGridLoaded, setIsCardGridLoaded] = useState(false);
  const [, setIsProfileHighlightLoaded] = useState(false);

  return (
    <div>
      <CardGrid onLoad={() => setIsCardGridLoaded(true)} />
      {isCardGridLoaded && (
        <div className="bckImg container-fluid py-lg-5 py-2 position-relative px-lg-0 px-3">
          <ProfileHighlight onLoad={() => setIsProfileHighlightLoaded(true)} />
        </div>
      )}
    </div>
  );
};

export default HowCanIHelp;
