import React from "react";
import { Link } from "react-router-dom";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import DOMPurify from "dompurify";
import { useDeleteDocument } from "../../hooks/useDeleteDocument";
import { useNavigate } from "react-router-dom";

type DocumentType = {
  id: string;
  client: string;
  testimonial: string;
  sequence: number;
};

const EditTestimonials: React.FC = () => {
  const { documents, loading, error } =
    useFetchDocuments<DocumentType>("testimonials");

  const { deleteDocument } = useDeleteDocument("testimonials");
  const navigate = useNavigate();

  /* eslint-disable no-restricted-globals */
  const deleteCard = (cardTitle: string, cardId: string) => {
    let msg = confirm(
      `Tem certeza que deseja deletar permanentemente o conteúdo ${cardTitle}?`
    );
    if (msg) deleteDocument(cardId);
    navigate("/admin/edit/depoimentos/");
  };
  /* eslint-enable no-restricted-globals */

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar documentos: {error}</div>;
  }

  return (
    <div>
      <div className="container-fluid pb-5 pt-5 mx-0 bckImg">
        <div className="container bg-white">
          {" "}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9 col-12 bg-white">
              <h2 className="text-start pt-3">
                Gerenciar conteúdo da seção: Depoimentos{" "}
              </h2>
              <div className="text-start pb-lg-4 ms-lg-1">
                {" "}
                <small className="text-danger">
                  Esse conteúdo aparece nas páginas: Home e Depoimentos
                </small>
              </div>
            </div>
            <div className="col-lg-3 col-12 bg-white d-flex justify-content-center align-items-center">
              <Link
                to="/admin/newtestimonial"
                className="btn btn-primary btn-md"
              >
                Adicionar novo item
              </Link>
            </div>
          </div>
          <table className="table table-striped text-start">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Nome do cliente/paciente</th>
                <th style={{ width: "60%" }}>Depoimento</th>

                <th style={{ width: "10%" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {documents &&
                documents.map((doc) => (
                  <tr key={doc.id}>
                    <td className="align-middle"> {doc.client}</td>

                    <td className="align-middle">
                      {" "}
                      {doc?.testimonial && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(doc.testimonial || ""),
                          }}
                          className="text-start mb-5"
                        />
                      )}
                    </td>
                    <td className="align-middle editButtons">
                      <Link
                        to={`/admin/edit-testimonial/${doc.id}`}
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>

                      <button
                        onClick={() => deleteCard(doc.client, doc.id)}
                        className="btn btn-danger ms-2 btn-sm w-100"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EditTestimonials;
