import React from "react";
import { Link } from "react-router-dom";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { useDeleteDocument } from "../../hooks/useDeleteDocument";

type DocumentType = {
  id: string;
  title: string;
  highlight: string;
  full_text: string;
  image?: string; // Torne a propriedade 'image' opcional
  card_image: string;
  intro: string;
};

const EditCards: React.FC = () => {
  const { documents, loading, error } =
    useFetchDocuments<DocumentType>("services");

  const { deleteDocument } = useDeleteDocument("services");

  /* eslint-disable no-restricted-globals */
  const deleteCard = (
    cardTitle: string,
    cardId: string,
    cardImage?: string
  ) => {
    let msg = confirm(
      `Tem certeza que deseja deletar permanentemente o conteúdo ${cardTitle}?`
    );
    if (msg) deleteDocument(cardId, cardImage);
  };
  /* eslint-enable no-restricted-globals */

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar documentos: {error}</div>;
  }

  return (
    <div>
      <div className="container-fluid pb-5 pt-5 mx-0 bckImg">
        <div className="container bg-white">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9 col-12 bg-white">
              <h2 className="text-start pt-3">
                Gerenciar conteúdo da seção: como posso te ajudar{" "}
              </h2>
              <div className="text-start pb-lg-4 ms-lg-1">
                {" "}
                <small className="text-danger">
                  Esse conteúdo aparece nas páginas: Home, Sobre mim e Como
                  posso ajudar
                </small>
              </div>
            </div>
            <div className="col-lg-3 col-12 bg-white d-flex justify-content-center align-items-center">
              <Link to="/admin/newcard" className="btn btn-primary btn-md">
                Adicionar novo item
              </Link>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <table className="table table-striped text-start">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th>Nome do card</th>
                  <th>Thumbnail</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {documents &&
                  documents.map((doc) => (
                    <tr key={doc.id}>
                      <td className="align-middle">{doc.title}</td>
                      <td className="align-middle">
                        <img
                          src={doc.image}
                          width="150px"
                          alt={doc.title}
                          className="img-fluid"
                        />
                      </td>
                      <td className="align-middle">
                        <Link
                          to={`/admin/editcard/${doc.id}`}
                          className="btn btn-warning btn-sm w-100"
                        >
                          Editar
                        </Link>
                      </td>
                      <td className="align-middle">
                        <button
                          onClick={() =>
                            deleteCard(doc.title, doc.id, doc.image)
                          }
                          className="btn btn-danger btn-sm w-100"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCards;
