import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useInsertDocument } from "../../hooks/useInsertDocument";

const AddNewTestimonial: React.FC = () => {
  const { insertDocument } = useInsertDocument("testimonials");
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  const titleRef = useRef<HTMLInputElement>(null); // Ref for title input

  // Estados individuais para cada campo do formulário
  const [testimonialText, setTestimonialText] = useState("");
  const [client, setClient] = useState("");
  const [sequence, setSequence] = useState<number | undefined>(undefined);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "testimonial":
        setTestimonialText(value);
        break;
      case "client":
        setClient(value);
        break;
      case "sequence":
        setSequence(Number(value));
        break;
      default:
        break;
    }
  };

  const handleEditorChange = (value: string) => {
    setTestimonialText(value);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!testimonialText)
      newErrors.testimonialText = "Texto do depoimento é obrigatório";
    if (!client) newErrors.client = "Nome do cliente é obrigatório";

    if (sequence === undefined)
      newErrors.sequence = "Ordem de exibição é obrigatória";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    const document = {
      testimonial: testimonialText,
      client,
      sequence,
    };

    await insertDocument(document);
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        navigate("/admin/edit/depoimentos/");
      }, 2000);
    }
  }, [submitted, navigate]);

  useEffect(() => {
    // After submission, focus on the title input
    if (submitted && titleRef.current) {
      titleRef.current.focus();
      titleRef.current.select(); // Selecting text in the input
    }
  }, [submitted]);

  return (
    <div className="container-fluid pb-4 pt-5 mx-0 bckImg">
      <div className="container p-lg-4 bg-white">
        <h2 className="text-start pb-lg-4">
          Adicionando novo item à seção: Depoimentos
        </h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {submitted ? (
              <div style={{ height: "50vh" }}>
                <div className="alert alert-success" role="alert">
                  Conteúdo adicionado com sucesso. Redirecionando para Dashboard
                  de Depoimentos...
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-100 text-start">
                <div className="form-group mb-4">
                  <label htmlFor="client" className="fw-bold">
                    Nome do cliente*
                  </label>
                  <input
                    type="text"
                    name="client"
                    value={client}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                  {errors.client && (
                    <div className="invalid-feedback">{errors.client}</div>
                  )}
                </div>
                <div className="row pb-lg-4">
                  <div className="form-group col-lg-6">
                    <label htmlFor="sequence" className="fw-bold">
                      Ordem de exibição *{" "}
                      <small>
                        (ex: 1, 2, 3, 4...) insira um número inteiro
                      </small>
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      value={sequence !== undefined ? sequence : ""}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                      className={`form-control ${errors.sequence ? "is-invalid" : ""}`}
                    />
                    {errors.sequence && (
                      <div className="invalid-feedback">{errors.sequence}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="full_text" className="fw-bold">
                    Texto do depoimento: *
                  </label>
                  <ReactQuill
                    value={testimonialText}
                    onChange={handleEditorChange}
                    className={`form-control ${errors.testimonialText ? "is-invalid" : ""}`}
                    theme="snow"
                  />
                  {errors.testimonialText && (
                    <div className="invalid-feedback">
                      {errors.testimonialText}
                    </div>
                  )}
                </div>

                <div className="row text-center mt-3">
                  <div className="form-group mb-4 align-items-end d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn rounded-pill bg-black text-white"
                      style={{ width: "200px" }}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewTestimonial;
