import React, { useState } from "react";

import TopHighlight from "../../components/TopHighlight/TopHighlight";
import CardGrid from "../../components/CardGrid/CardGrid";
import ProfileHighlight from "../../components/ProfileHighlight/ProfileHighlight";
import Testimonials from "../../components/Testimonials/Testimonials";

const Home = () => {
  const [isTopHighlightLoaded, setIsTopHighlightLoaded] = useState(false);
  const [isCardGridLoaded, setIsCardGridLoaded] = useState(false);
  const [isProfileHighlightLoaded, setIsProfileHighlightLoaded] =
    useState(false);

  return (
    <React.Fragment>
      <TopHighlight onLoad={() => setIsTopHighlightLoaded(true)} />
      {isTopHighlightLoaded && (
        <CardGrid onLoad={() => setIsCardGridLoaded(true)} />
      )}
      {isTopHighlightLoaded && isCardGridLoaded && (
        <div className="bckImg container-fluid py-lg-5 py-2 position-relative px-lg-0 px-3">
          <ProfileHighlight onLoad={() => setIsProfileHighlightLoaded(true)} />
          {isProfileHighlightLoaded && <Testimonials />}
        </div>
      )}
    </React.Fragment>
  );
};

export default Home;
