import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

// Components
import NavBar from "./components/NavBar/NavBar";
import NavBarAdmin from "./components/NavBar/NavBarAdmin";
import Home from "./pages/Home/Home";
import Service from "./pages/Service/Service";
import Footer from "./components/Footer/Footer";
import AboutMe from "./pages/AboutMe/AboutMe";
import HowCanIHelp from "./pages/HowCanIHelp/HowCanIHelp";
import TestimonialsPage from "./pages/TestimonialsPage/TestimonialsPage";
import WhatsAppLink from "./components/WhatssAppLink/WhatsAppLink";
import Login from "./pages/Login/Login";

// Context
import { AuthProvider } from "./context/AuthContext";
import { onAuthStateChanged, User } from "firebase/auth";
import { useAuthentication } from "./hooks/useAuthentication";
import Dashboard from "./pages/Dashboard/Dashboard";

import EditPage from "./pages/Edit/Edit";
import EditCards from "./pages/Edit/EditCards";
import EditSingleCard from "./pages/Edit/EditSingleCard";
import EditTestimonials from "./pages/Edit/EditTestimonials";
import AddNewContent from "./pages/AddNewContent/AddNewContent";
import EditSingleTestimonial from "./pages/Edit/EditSinglesTestimonial";
import AddNewTestimonial from "./pages/AddNewContent/AddNewTestimonial";

const App: React.FC = () => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const { auth } = useAuthentication();
  const loadingUser = user === undefined;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loadingUser) {
    return (
      <div className="d-flex justify-content-center pt-5">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }

  return (
    <div className="App" style={{ height: "100vh" }}>
      <AuthProvider value={{ user }}>
        <BrowserRouter>
          <NavigationBar user={user} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/psicologia/:slug/" element={<Service />} />
            <Route path="/jocemara-mognon" element={<AboutMe />} />
            <Route path="/como-posso-te-ajudar" element={<HowCanIHelp />} />
            <Route path="/depoimentos" element={<TestimonialsPage />} />
            <Route path="/login" element={<Login />} />

            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to="/" />}
            />

            <Route
              path="admin/edit/:collection/:id"
              element={user ? <EditPage /> : <Navigate to="/" />}
            />
            <Route
              path="admin/edit/como-posso-te-ajudar/"
              element={user ? <EditCards /> : <Navigate to="/" />}
            />

            <Route
              path="admin/editcard/:id"
              element={user ? <EditSingleCard /> : <Navigate to="/" />}
            />
            <Route
              path="admin/edit-testimonial/:id"
              element={user ? <EditSingleTestimonial /> : <Navigate to="/" />}
            />
            <Route
              path="admin/edit/depoimentos/"
              element={user ? <EditTestimonials /> : <Navigate to="/" />}
            />

            <Route
              path="admin/newcard/"
              element={user ? <AddNewContent /> : <Navigate to="/" />}
            />
            <Route
              path="admin/newtestimonial"
              element={user ? <AddNewTestimonial /> : <Navigate to="/" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
          <ConditionalWhatsAppLink />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
};

const NavigationBar: React.FC<{ user: User | null | undefined }> = ({
  user,
}) => {
  const location = useLocation();
  return location.pathname.includes("/admin") ||
    location.pathname.includes("/dashboard") ? (
    user ? (
      <NavBarAdmin />
    ) : (
      <NavBar />
    )
  ) : (
    <NavBar />
  );
};

const ConditionalWhatsAppLink: React.FC = () => {
  const location = useLocation();
  return !location.pathname.includes("/admin") &&
    !location.pathname.includes("/dashboard") ? (
    <WhatsAppLink className="icon_whatsapp" />
  ) : null;
};

export default App;
