import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDocument } from "../../hooks/useFetchDocument";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  uploadImageToStorage,
  deleteImageFromStorage,
} from "../../hooks/uploadImageToStorage";
import { useUpdateDocument } from "../../hooks/useUpdateDocument";

type DocumentType = {
  id: string;
  title: string;
  highlight: string;
  full_text: string;
  image: string;
  card_image: string;
  intro: string;
  sequence: number;
  slug: string;
};

const EditSingleCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { document, loading, error } = useFetchDocument<DocumentType>(
    "services",
    id || ""
  );
  const { updateDocument } = useUpdateDocument("services" || "");

  const [formData, setFormData] = useState<DocumentType | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  function normalizeTitle(title: string) {
    const normalizedTitle = title
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();
    return normalizedTitle;
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Carregue a nova imagem para o armazenamento
      const imageUrl = await uploadImageToStorage(file);

      // Atualize o estado se a URL da imagem for válida
      if (imageUrl && formData) {
        const updatedDoc = { ...formData, image: imageUrl };
        setFormData(updatedDoc);
      }
    }
  };

  useEffect(() => {
    if (document) {
      setFormData(document);
    }
  }, [document]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) =>
      prevData
        ? { ...prevData, [name]: name === "sequence" ? Number(value) : value }
        : null
    );
  };

  const handleEditorChange = (value: string) => {
    setFormData((prevData) =>
      prevData ? { ...prevData, full_text: value } : null
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      document?.image &&
      formData &&
      formData.image &&
      document?.image !== formData.image
    ) {
      await deleteImageFromStorage(document?.image);
    }

    if (formData) {
      // Normalizar o título e definir o slug
      const normalizedSlug = normalizeTitle(formData.title);
      formData.slug = normalizedSlug;

      // Atualizar o documento
      updateDocument(formData.id, formData);
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (submitted === true) {
      setTimeout(() => {
        navigate("/admin/edit/como-posso-te-ajudar/");
      }, 2000);
    }
  }, [submitted, navigate]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar documentos: {error}</div>;
  }

  if (!document) {
    return <div>Documento não encontrado!</div>;
  }

  return (
    <div className="container-fluid pb-5 pt-5 mx-0 bckImg">
      <div className="container p-lg-4 bg-white">
        <h2 className="text-start pb-lg-2">Editar conteúdo de:</h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {submitted ? (
              <div style={{ height: "50vh" }}>
                <div className="alert alert-success" role="alert">
                  Conteúdo atualizado com sucesso. Redirecionando para
                  Dashboard...
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-100 text-start">
                <div className="form-group mb-4">
                  <label htmlFor="title" className="fw-bold">
                    Título
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData?.title || ""}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-4">
                  <div>
                    <p className="fw-bold">Imagem:{"  "}</p>
                    <small>
                      *Altera tanto no card quanto na página do conteúdo. Para
                      não deformar a imagem, insira uma imagem jpg ou png com
                      tamanho e altura iguais, de 300px por 300px.
                    </small>
                  </div>
                  <div>
                    <img
                      src={formData?.image}
                      width="150px"
                      alt={formData?.title}
                      className="img-fluid pb-3"
                    />
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="intro" className="fw-bold">
                    Texto de introdução do card
                  </label>
                  <input
                    type="text"
                    name="intro"
                    value={formData?.intro || ""}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="row pb-lg-4">
                  <div className="form-group col-lg-4">
                    <label htmlFor="highlight" className="fw-bold">
                      Texto de destaque do card
                    </label>
                    <input
                      type="text"
                      name="highlight"
                      value={formData?.highlight || ""}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="sequence" className="fw-bold">
                      Ordem de exibição
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      value={formData?.sequence || ""}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="full_text" className="fw-bold">
                    Texto completo:
                  </label>
                  <ReactQuill
                    value={formData?.full_text || ""}
                    onChange={handleEditorChange}
                    className="form-control"
                    theme="snow"
                  />
                </div>

                <div className="row text-center mt-3">
                  <div className="form-group mb-4 align-items-end d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn rounded-pill bg-black text-white"
                      style={{ width: "200px" }}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSingleCard;
