import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";

export type FetchProps = string;

export const useFetchDocuments = <T extends { id: string }>(
  docCollection: FetchProps
) => {
  const [documents, setDocuments] = useState<T[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // deal with memory leak
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    async function loadData() {
      if (cancelled) {
        return;
      }

      setLoading(true);

      const collectionRef = collection(db, docCollection);

      try {
        const q = query(collectionRef, orderBy("sequence", "asc"));

        const unsubscribe = onSnapshot(
          q,
          (querySnapshot: QuerySnapshot<DocumentData>) => {
            const docs = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })) as T[];

            // console.log("Documents fetched:", docs);

            setDocuments(docs);

            if (docs.length === 0) {
              console.log("No documents found in the collection.");
            }
          },
          (error) => {
            console.error("Error fetching documents:", error);
            setError(error.message);
          }
        );

        return unsubscribe;
      } catch (error) {
        console.log("Error fetching documents:", error);
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [docCollection, cancelled]);

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  // console.log("Current documents state:", documents);

  return { documents, loading, error };
};
