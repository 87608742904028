import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFetchSingleFile } from "../../hooks/useFetchSingleFile";
import {
  uploadImageToStorage,
  deleteImageFromStorage,
} from "../../hooks/uploadImageToStorage";
import { useUpdateDocument } from "../../hooks/useUpdateDocument";

interface GenericDocument {
  id: string;
  [key: string]: any;
}

const EditPage: React.FC = () => {
  const { collection, id } = useParams<Record<string, string | undefined>>();
  const { updateDocument } = useUpdateDocument(collection || "");
  const [updated, setUpdated] = useState<GenericDocument | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  // Garanta que o hook seja sempre chamado
  const { document, loading, error } = useFetchSingleFile<GenericDocument>(
    collection || ""
  );

  useEffect(() => {
    if (document) {
      setUpdated({ ...document });
    }
  }, [document]);

  useEffect(() => {
    if (submitted === true) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    }
  }, [submitted, navigate]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    if (updated) {
      const updatedDoc = { ...updated };
      updatedDoc[id] = value;
      setUpdated(updatedDoc);
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Carregue a nova imagem para o armazenamento
      const imageUrl = await uploadImageToStorage(file);

      // Atualize o estado se a URL da imagem for válida
      if (imageUrl && updated) {
        const updatedDoc = { ...updated, image_banner: imageUrl };
        setUpdated(updatedDoc);
      }
    }
  };

  if (!collection || !id) {
    return <div>Parâmetros inválidos!</div>;
  }

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar o documento: {error}</div>;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      updated &&
      updated.image_banner &&
      document?.image_banner !== updated.image_banner
    ) {
      await deleteImageFromStorage(document?.image_banner);
    }

    if (updated) {
      updateDocument(id || "", updated);
      setSubmitted(true);
    }
  };

  const handleEditorChange = (value: string, key: string) => {
    if (updated) {
      const updatedDoc = { ...updated, [key]: value };
      setUpdated(updatedDoc);
    }
  };

  return (
    <div className="container bg-white pt-4 text-start">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          {submitted ? (
            <div style={{ height: "50vh" }}>
              <div className="alert alert-success" role="alert">
                Conteúdo atualizado com sucesso. Redirecionando para
                Dashboard...
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-8 d-flex justify-content-start align-items-center">
                  <h2>Editar {document?.title}</h2>
                </div>
                <div className="col-lg-4">
                  {document?.image && (
                    <img
                      src={document?.image}
                      alt={document?.title}
                      className="img-fluid mb-2"
                    />
                  )}
                </div>
              </div>
              <hr className="mb-4"></hr>

              {updated &&
                document &&
                Object.keys(updated).map(
                  (key) =>
                    key !== "id" &&
                    key !== "sequence" &&
                    key !== "image" && (
                      <div className="form-group mb-4" key={key}>
                        <label htmlFor={key} className="mb-2 fw-bold">
                          {key === "image_banner"
                            ? "Imagem: "
                            : key.charAt(0).toUpperCase() + key.slice(1)}
                        </label>
                        {key === "image_banner" ? (
                          <div className="row">
                            {document[key]?.length > 0 &&
                            !updated["image_banner"] ? (
                              <div className="col-lg-4">
                                <img
                                  src={document[key]}
                                  alt={key}
                                  className="mb-2"
                                  width="300px"
                                />
                              </div>
                            ) : null}
                            {updated["image_banner"] && (
                              <div className="col-lg-4">
                                <p>Nova imagem selecionada:</p>
                                <img
                                  src={updated["image_banner"]}
                                  alt="Nova Imagem Selecionada"
                                  className="mb-2"
                                  width="300px"
                                />
                              </div>
                            )}
                            <input
                              type="file"
                              className="form-control"
                              id={key}
                              onChange={handleImageUpload}
                              accept="image/*"
                            />
                          </div>
                        ) : key === "full_text" || key === "address" ? (
                          <ReactQuill
                            value={updated[key]}
                            onChange={(value) => handleEditorChange(value, key)}
                            className="form-control"
                            theme="snow"
                          />
                        ) : typeof updated[key] === "string" ? (
                          <textarea
                            className="form-control"
                            id={key}
                            rows={3}
                            value={updated[key]}
                            onChange={handleInputChange}
                            placeholder={`Digite o ${key} aqui...`}
                          />
                        ) : (
                          <input
                            type={typeof updated[key]}
                            className="form-control"
                            id={key}
                            value={updated[key]}
                            onChange={handleInputChange}
                            placeholder={`Digite o ${key} aqui...`}
                          />
                        )}
                      </div>
                    )
                )}
              <div className="form-group mb-4 align-items-end d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn rounded-pill bg-black text-white"
                  style={{ width: "200px" }}
                >
                  Enviar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPage;
