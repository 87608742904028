import React, { useState } from "react";

import fotoJoce from "./img/jocemara_aboutme.jpg";

//components
import WhatsAppLink from "../../components/WhatssAppLink/WhatsAppLink";

//hooks
import { useFetchSingleFile } from "../../hooks/useFetchSingleFile";
import CardGrid from "../../components/CardGrid/CardGrid";

interface ProfileHighlightProps {
  id: string;
  title: string;
  subtitle: string;
  full_text: string;
}

function AboutMe() {
  const {
    document: aboutMe,
    loading,
    error,
  } = useFetchSingleFile<ProfileHighlightProps>("about_me");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCardGridLoaded, setIsCardGridLoaded] = useState(false);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="container-fluid bckImg">
        <div className="container py-5">
          <div className="row d-flex justify-content-start align-items-start">
            <div className="col-lg-4 col-12 px-0">
              <img src={fotoJoce} alt="Jocemara Mognon" className="img-fluid" />
            </div>
            <div className="col-lg-8 col-12 py-5 text-start bg-white">
              {aboutMe && (
                <div className="px-4 ">
                  <h5 className="fw-bold mb-4">Sobre mim</h5>
                  <h2 className="fw-100">{aboutMe.title}</h2>
                  <h4 className="mb-5">{aboutMe.subtitle}</h4>
                  {aboutMe?.full_text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: aboutMe.full_text }}
                      className="text-start mb-5"
                    />
                  )}
                </div>
              )}
              <div className="ps-0 ps-lg-4 d-flex justify-content-lg-start justify-content-center">
                <WhatsAppLink className="custom-primary-btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {aboutMe && <CardGrid onLoad={() => setIsCardGridLoaded(true)} />}
    </>
  );
}

export default AboutMe;
