import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDocument } from "../../hooks/useFetchDocument";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useUpdateDocument } from "../../hooks/useUpdateDocument";

type DocumentType = {
  id: string;
  client: string;
  testimonial: string;
  sequence: number;
};

const EditSingleTestimonial: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { document, loading, error } = useFetchDocument<DocumentType>(
    "testimonials",
    id || ""
  );
  const { updateDocument } = useUpdateDocument("testimonials");

  const [formData, setFormData] = useState<DocumentType | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (document) {
      setFormData(document);
    }
  }, [document]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) =>
      prevData
        ? { ...prevData, [name]: name === "sequence" ? Number(value) : value }
        : null
    );
  };

  const handleEditorChange = (value: string) => {
    setFormData((prevData) =>
      prevData ? { ...prevData, testimonial: value } : null
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData) {
      // Atualizar o documento
      await updateDocument(formData.id, formData);
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        navigate("/admin/edit/depoimentos");
      }, 2000);
      return () => clearTimeout(timer); // Clear timeout on cleanup
    }
  }, [submitted, navigate]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar documentos: {error}</div>;
  }

  if (!document) {
    return <div>Documento não encontrado!</div>;
  }

  return (
    <div className="container-fluid pb-5 pt-5 mx-0 bckImg">
      <div className="container p-lg-4 bg-white">
        <h2 className="text-start pb-lg-2">Editar conteúdo de:</h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {submitted ? (
              <div style={{ height: "50vh" }}>
                <div className="alert alert-success" role="alert">
                  Conteúdo atualizado com sucesso. Redirecionando para
                  Dashboard...
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-100 text-start">
                <div className="row pb-lg-4">
                  <div className="form-group col-lg-4">
                    <label htmlFor="highlight" className="fw-bold">
                      Nome do paciente
                    </label>
                    <input
                      type="text"
                      name="client"
                      value={formData?.client || ""}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="sequence" className="fw-bold">
                      Ordem de exibição
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      value={formData?.sequence || ""}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="testimonial" className="fw-bold">
                    Texto completo:
                  </label>
                  <ReactQuill
                    value={formData?.testimonial || ""}
                    onChange={handleEditorChange}
                    className="form-control"
                    theme="snow"
                  />
                </div>

                <div className="row text-center mt-3">
                  <div className="form-group mb-4 align-items-end d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn rounded-pill bg-black text-white"
                      style={{ width: "200px" }}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSingleTestimonial;
