import React from "react";

import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";

const Dashboard = () => {
  return (
    <div>
      <div className="container-fluid  pt-5 mx-0 bckImg pb-lg-3">
        <div className="container bg-white">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-12 bg-white my-lg-5 py-lg-5 my-3 py-3">
              <h2 className="text-start p-3 pb-5">
                Gerenciar páginas e conteúdos do site
              </h2>
              <table
                className={`${styles.tableDashboard} table table-striped text-start table-hover`}
              >
                <thead>
                  <tr>
                    <th style={{ width: "40%" }}>Nome do elemento/página</th>
                    <th>Url</th>
                    <th style={{ width: "25%" }}>Thumbnail</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-start align-middle">
                    <td>Banner de topo da Home</td>
                    <td>/</td>
                    <td>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/banner_topo.jpg?alt=media&token=bebe55f0-d2e8-45ba-8edc-746b8fbc007c"
                        width="100%"
                        alt=""
                      />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/home_destaque/x83ySqq1o4qZAi8ABD8j"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle">
                    <td>Seção/Página: como posso te ajudar</td>
                    <td>/como-posso-te-ajudar</td>
                    <td>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/como_posso_te_ajudar.jpg?alt=media&token=f6fc9110-7f94-4627-9051-a9202c1fd526"
                        width="100%"
                        alt=""
                      />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/como-posso-te-ajudar/"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle">
                    <td>Seção: destaque do perfil</td>
                    <td>/</td>
                    <td>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/destaque_perfil.jpg?alt=media&token=d357b98b-baa5-40c5-9acc-eff0a01fae36"
                        width="100%"
                        alt=""
                      />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/profile_highlight/zwz8I52lhJX7Zcj7yD6L"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle">
                    <td>Página: Sobre mim</td>
                    <td>/jocemara-mognon</td>
                    <td>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/sobre_mim.jpg?alt=media&token=115e42d7-f092-48da-8533-9c9545fa1a3f"
                        width="100%"
                        alt=""
                      />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/about_me/j1Oi2FyCu7I21N3AkEDa"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle">
                    <td>Seção/Página: Depoimentos</td>
                    <td>/depoimentos</td>
                    <td>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/jocemara-mognon-website.appspot.com/o/depoimentos.jpg?alt=media&token=6007178e-a16b-41a9-ba55-9c8a5262f364"
                        width="100%"
                        alt=""
                      />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/depoimentos/"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle">
                    <td>
                      Seção: contato pelo Whatsapp (Editar número e/ou mensagem
                      padrão)
                    </td>
                    <td>/</td>
                    <td>
                      <img src="" width="100%" alt="" />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/contact/DMbTOXvKo5WdcLQc77C7"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-start align-middle mb-4">
                    <td>Endereço do rodapé (Editar endereço e/ou CRP)</td>
                    <td>/</td>
                    <td>
                      <img src="" width="100%" alt="" />
                    </td>
                    <td>
                      <Link
                        to="/admin/edit/address/SS9PUwnOhQtYihujJH9w"
                        className="btn btn-warning btn-sm"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
